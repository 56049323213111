import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ProfileSelect',
  props: {
    options: {
      type: Array,
      required: true
    },
    default: {
      type: [String, Number],
      required: false,
      default: ''
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    error: {
      type: String,
      default: null
    },
    drop: {
      type: String,
      default: 'down'
    },
    placeholder: {
      type: String,
      default: null
    },
    isDisabled: {
      default: false
    }
  },
  data: function data() {
    return {
      selected: null,
      open: false,
      errorMessage: null
    };
  },
  watch: {
    error: function error() {
      this.errorMessage = this.error;
    }
  },
  methods: {
    openList: function openList() {
      if (!this.isDisabled) this.open = !this.open;
    },
    setOption: function setOption(option) {
      this.resetFieldError();
      this.selected = option;
      this.open = false;
      this.$emit('input', option);
    },
    resetFieldError: function resetFieldError() {
      this.errorMessage = null;
    }
  },
  mounted: function mounted() {
    this.$emit('input', this.selected);
  },
  created: function created() {
    if (this.default) this.selected = this.default;
  }
};